export const calendar = [
  {
    month: "JANEIRO",
    items: [
      {
        data: "14 - Terça-feira - 20H - Lua Cheia",
        desc: "REUNIÃO FESTA DE YEMANJÁ",
      },
      {
        data: "20 - Segunda-feira - 20H - Lua Cheia",
        desc: "HOMENAGEM A OXOSSI",
      },
    ],
  },
  {
    month: "FEVEREIRO",
    items: [
      {
        data: "1 - SÁBADO - 20H - Lua Nova",
        desc: "XXVII FESTA DA YEMANJÁ",
      },
    ],
  },
  {
    month: "MARÇO",
    items: [
      {
        data: "11 - Terça-feira - 20H - Lua Crescente",
        desc: "INÍCIO DAS ATIVIDADES - CHAMADA DE OXOSSI",
      },
      {
        data: "12 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "13 - Quinta-feira - 20H - Lua Crescente",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "18 - Terça-feira - 20H - Lua Cheia",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YANSÃ",
      },
      {
        data: "19 - Quarta-feira - 14H30 - Lua Cheia",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "20 - Quinta-feira - 20H - Lua Cheia",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "25 - Terça-feira - 20H - Lua Minguante",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE PRETOS VELHOS",
      },
      {
        data: "26 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "27 - Quinta-feira - 20H - Lua Minguante",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
    ],
  },
  {
    month: "ABRIL",
    items: [
      {
        data: "1 - Terça-feira - 20H - Lua Nova",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXALÁ",
      },
      {
        data: "2 - Quarta-feira - 14H30 - Lua Nova",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "3 - Quinta-feira - 20H - Lua Nova",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "8 - Terça-feira - 20H - Lua Crescente",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YEMANJÁ",
      },
      {
        data: "9 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "10 - Quinta-feira - 20H - Lua Crescente",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "12 - SÁBADO - 20H - Lua Cheia",
        desc: "1ª DEMANDA NO REINO DE JUNA BOMY",
      },
      {
        data: "14 ATÉ 18 - 0 - 0 - 0",
        desc: "RECESSO EM RAZÃO DA SEMANA SANTA",
      },
      {
        data: "22 - Terça-feira - 20H - Lua Minguante",
        desc: "NÃO TEM SESSÃO EM RAZÃO DA FESTA DE OGUM",
      },
      {
        data: "23 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "NÃO HAVERÁ SESSÃO DE ATENDIMENTO COM PRETOS VELHOS - FESTA OGUM",
      },
      {
        data: "23 - Quarta-feira - 20H - Lua Minguante",
        desc: "HOMENAGEM PARA OGUM",
      },
      {
        data: "24 - Quinta-feira - 20H - Lua Minguante",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "29 - Terça-feira - 20H - Lua Nova",
        desc: "SESSÃO FECHADA AO PÚBLICO (REUNIÃO/TRABALHO)",
      },
      {
        data: "30 - Quarta-feira - 14H30 - Lua Nova",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
    ],
  },
  {
    month: "MAIO",
    items: [
      {
        data: "1 - Quinta-feira - 20H - Lua Nova",
        desc: "NÃO HAVERÁ SESSÃO - FERIADO DIA DO TRABALHADOR",
      },
      {
        data: "6 - Terça-feira - 20H - Lua Crescente",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXOSSI",
      },
      {
        data: "7 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "8 - Quinta-feira - 20H - Lua Crescente",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "13 - Terça-feira - 20H - Lua Cheia",
        desc: "FESTA DE PRETOS VELHOS",
      },
      {
        data: "14 - Quarta-feira - 14H30 - Lua Cheia",
        desc: "NÃO HAVERÁ SESSÃO EM RAZÃO DA FESTA DOS PRETOS VELHOS",
      },
      {
        data: "15 - Quinta-feira - 20H - Lua Cheia",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "20 - Terça-feira - 20H - Lua Minguante",
        desc: "NÃO HAVERÁ SESSÃO ABERTA AO PÚBLICO // REUNIÃO OU TRABALHO FECHADO",
      },
      {
        data: "21 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "22 - Quinta-feira - 20H - Lua Minguante",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "24 - SÁBADO - 20H - Lua Minguante",
        desc: "2ª DEMANDA NO REINO DE JUNA BOMY",
      },
      {
        data: "27 - Terça-feira - 20H - Lua Nova",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE XANGÕ",
      },
      {
        data: "28 - Quarta-feira - 14H30 - Lua Nova",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "29 - Quinta-feira - 20H - Lua Nova",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
    ],
  },
  {
    month: "JUNHO",
    items: [
      {
        data: "3 - Terça-feira - 20H - Lua Crescente",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXUM",
      },
      {
        data: "4 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "5 - Quinta-feira - 20H - Lua Crescente",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "10 - Terça-feira - 20H - Lua Crescente",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YANSÃ",
      },
      {
        data: "11 - Quarta-feira - 14H30 - Lua Cheia",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "12 - Quinta-feira - 20H - Lua Cheia",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "14 - SÁBADO - 20H - Lua Cheia",
        desc: "1ª ENGIRA NO REINO DE JUNA BOMY",
      },
      {
        data: "17 - Terça-feira - 20H - Lua Cheia",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXUALÁ",
      },
      {
        data: "18 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "19 - Quinta-feira - 20H - Lua Minguante",
        desc: "NÃO HAVERÁ SESSÃO - FERIADO CORPUS CHRISTI",
      },
      {
        data: "24 - Terça-feira - 20H - Lua Minguante",
        desc: "DIA DE SÃO JOAÕ BATISTA - BATIZADO DE ADULTOS - CHAMADA DO ORIENTE",
      },
      {
        data: "25 - Quarta-feira - 14H30 - Lua Nova",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "26 - Quinta-feira - 20H - Lua Nova",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
    ],
  },
  {
    month: "JULHO",
    items: [
      {
        data: "1 - Terça-feira - 20H - Lua Nova",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YEMANJÁ",
      },
      {
        data: "2 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "3 - Quinta-feira - 20H - Lua Crescente",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "8 - Terça-feira - 20H - Lua Crescente",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OGUM",
      },
      {
        data: "9 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "10 - Quinta-feira - 20H - Lua Cheia",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "15 - Terça-feira - 20H - Lua Cheia",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXOSSI",
      },
      {
        data: "16 - Quarta-feira - 14H30 - Lua Cheia",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "17 - Quinta-feira - 20H - Lua Minguante",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "22 - Terça-feira - 20H - Lua Minguante",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE PRETOS VELHOS",
      },
      {
        data: "23 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "24 - Quinta-feira - 20H - Lua Nova",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "29 - Terça-feira - 20H - Lua Nova",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE XANGÔ",
      },
      {
        data: "30 - Quarta-feira - 14H30 - Lua Nova",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "31 - Quinta-feira - 20H - Lua Nova",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
    ],
  },
  {
    month: "AGOSTO",
    items: [
      {
        data: "5 - Terça-feira - 20H - Lua Crescente",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXUM",
      },
      {
        data: "6 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "7 - Quinta-feira - 20H - Lua Crescente",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "12 - Terça-feira - 20H - Lua Cheia",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YANSÃ",
      },
      {
        data: "13 - Quarta-feira - 14H30 - Lua Cheia",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "14 - Quinta-feira - 20H - Lua Cheia",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "16 - SÁBADO - 20H - Lua Minguante",
        desc: "3ª DEMANDA NO REINO DE JUNA BOMY",
      },
      {
        data: "19 - Terça-feira - 20H - Lua Minguante",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE PRETOS VELHOS",
      },
      {
        data: "20 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "21 - Quinta-feira - 20H - Lua Minguante",
        desc: "CHAMADA DE CATIMBÓ - FECHADA AO PÚBLICO",
      },
      {
        data: "26 - Terça-feira - 20H - Lua Nova",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXALÁ",
      },
      {
        data: "27 - Quarta-feira - 14H30 - Lua Nova",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "28 - Quinta-feira - 20H - Lua Nova",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
    ],
  },
  {
    month: "SETEMBRO",
    items: [
      {
        data: "2 - Terça-feira - 20H - Lua Crescente",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YEMANJÁ",
      },
      {
        data: "3 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "4 - Quinta-feira - 20H - Lua Crescente",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "9 - Terça-feira - 20H - Lua Cheia",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OGUM",
      },
      {
        data: "10 - Quarta-feira - 14H30 - Lua Cheia",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "11 - Quinta-feira - 20H - Lua Cheia",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "16 - Terça-feira - 20H - Lua Minguante",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE PRETOS VELHOS",
      },
      {
        data: "17 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "18 - Quinta-feira - 20H - Lua Minguante",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "23 - Terça-feira - 20H - Lua Nova",
        desc: "AMACI DE CABEÇA - FECHADA AO PÚBLICO",
      },
      {
        data: "24 - Quarta-feira - 14H30 - Lua Nova",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "25 - Quinta-feira - 20H - Lua Nova",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "27 - SÁBADO - 20H - Lua Nova",
        desc: "FESTA DE COSME E DAMIÃO - BATIZADO INFANTIL",
      },
      {
        data: "30 - Terça-feira - 20H - Lua Crescente",
        desc: "HOMENAGEM A XANGÔ E ANIVERSÁRIO DO REINO DE JUNA BOMY",
      },
    ],
  },
  {
    month: "OUTUBRO",
    items: [
      {
        data: "1 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "NÃO HAVERÁ SESSÃO - MOTIVO HOMENAGEM A XANGÔ",
      },
      {
        data: "2 - Quinta-feira - 20H - Lua Crescente",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "7 - Terça-feira - 20H - Lua Cheia",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXOSSI (PAJELANÇA)",
      },
      {
        data: "8 - Quarta-feira - 14H30 - Lua Cheia",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "9 - Quinta-feira - 20H - Lua Cheia",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "14 - Terça-feira - 20H - Lua Minguante",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE PRETOS VELHOS",
      },
      {
        data: "15 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "16 - Quinta-feira - 20H - Lua Minguante",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "18 - SÁBADO - 20H - Lua Minguante",
        desc: "4ª DEMANDA NO REINO DE JUNA BOMY",
      },
      {
        data: "21 - Terça-feira - 20H - Lua Nova",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE XANGÔ",
      },
      {
        data: "22 - Quarta-feira - 14H30 - Lua Nova",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "23 - Quinta-feira - 20H - Lua Nova",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "28 - Terça-feira - 20H - Lua Nova",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXUM",
      },
      {
        data: "29 - Quarta-feira - 14H30 - Lua Crescente",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "30 - Quinta-feira - 20H - Lua Crescente",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
    ],
  },
  {
    month: "NOVEMBRO",
    items: [
      {
        data: "4 - Terça-feira - 20H - Lua Crescente",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YANSÃ",
      },
      {
        data: "5 - Quarta-feira - 14H30 - Lua Cheia",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "6 - Quinta-feira - 20H - Lua Cheia",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "8 - SÁBADO - 20H - Lua Cheia",
        desc: "2ª ENGIRA NO REINO DE JUNA BOMY",
      },
      {
        data: "11 - Terça-feira - 20H - Lua Cheia",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OXALÁ",
      },
      {
        data: "12 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "13 - Quinta-feira - 20H - Lua Minguante",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
      {
        data: "18 - Terça-feira - 20H - Lua Minguante",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA PRETOS VELHOS",
      },
      {
        data: "19 - Quarta-feira - 14H30 - Lua Minguante",
        desc: "ATENDIMENTO COM PRETOS VELHOS",
      },
      {
        data: "20 - QUINTA - 20H - Lua Nova",
        desc: "NÃO HAVERÁ SESSÃO - FERIADO",
      },
      {
        data: "25 - Terça-feira - 20H - Lua Nova",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE YEMANJÁ",
      },
      {
        data: "26 - Quarta-feira - 14H30 - Lua Nova",
        desc: "ATENDIMENTO COM PRETOS VELHOS - ÚLTIMA SESSÃO DO ANO",
      },
      {
        data: "27 - Quinta-feira - 20H - Lua Nova",
        desc: "ATENDIMENTO CABOCLOS E PRETOS VELHOS",
      },
    ],
  },
  {
    month: "DEZEMBRO",
    items: [
      {
        data: "2 - Terça-feira - 20H - Lua Nova",
        desc: "DESENVOLVIMENTO MEDIÚNICO - CHAMADA DE OGUM",
      },
      {
        data: "3 - Quarta-feira - 14H30 - Lua Nova",
        desc: "NÃO HAVERÁ SESSÃO - MOTIVO FESTA DE YANSÃ",
      },
      {
        data: "4 - Quinta-feira - 20H - Lua Cheia",
        desc: "HOMENAGEM A YANSÃ",
      },
      {
        data: "8 - Segunda-feira - 20H - Lua Cheia",
        desc: "HOMENAGEM A OXUM",
      },
      {
        data: "9 - Terça-feira - 20H - Lua Cheia",
        desc: "NÃO HAVERÁ SESSÃO - MOTIVO FESTA DE OXUM",
      },
      {
        data: "11 - Quinta-feira - 20H - Lua Minguante",
        desc: "SESSÃO FECHADA AO PÚBLICO - BANHO",
      },
      {
        data: "16 - Terça-feira - 20H - Lua Nova",
        desc: "ENTREGA DO ANO NO REINO DE JUNA BOMY",
      },
    ],
  },
];
